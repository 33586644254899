"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetPomodoroCtrl = void 0;
const alarmclock_countdown_timer_1 = require("@src/shared/alarmclock~countdown~timer");
const utils_1 = require("@src/shared/utils");
class SetPomodoroCtrl extends alarmclock_countdown_timer_1.BaseSetCtrl {
    constructor($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl) {
        super($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl);
        this.$scope = $scope;
        this.$locale = $locale;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$element = $element;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.UploadDataUrl = UploadDataUrl;
        this.sounds = this.SoundService.getOptions();
        this.played = false;
        this.timerData = {
            // pomodoro
            step: 'pomodoro',
            pomodoro: (0, utils_1.rangeInitial)(this.$modalScope.data.pomodoro, 25, 25, 90),
            shortBreak: (0, utils_1.rangeInitial)(this.$modalScope.data.shortBreak, 5, 3, 30),
            longBreak: (0, utils_1.rangeInitial)(this.$modalScope.data.longBreak, 15, 15, 45),
            longBreakInterval: (0, utils_1.rangeInitial)(this.$modalScope.data.longBreakInterval, 4, 1, 10),
            dailyGoal: (0, utils_1.rangeInitial)(this.$modalScope.data.dailyGoal, 10, 1, 20),
            autoStartBreaks: this.$modalScope.data.autoStartBreaks,
            autoStartPomodoros: this.$modalScope.data.autoStartPomodoros,
            autoComplete: this.$modalScope.data.autoComplete,
            soundRepeat: false,
            soundVolume: (0, utils_1.rangeInitial)(this.$modalScope.data.soundVolume, 75, 0, 100, 25),
            soundMessage: this.$modalScope.data.soundMessage,
            sound: this.$modalScope.data.sound,
        };
    }
    $onInit() {
        super.$onInit();
        this.$scope.$watch('$ctrl.timerData.soundVolume', (soundVolume) => {
            const soundInstances = this.SoundService.soundbox.instances;
            if (soundInstances.length) {
                soundInstances[soundInstances.length - 1].volume = soundVolume / 100;
            }
        });
        this.$scope.$watch('$ctrl.timerData', (timerData, oldTimerData) => {
            var _a, _b;
            if (((_a = timerData.sound) === null || _a === void 0 ? void 0 : _a.label) != ((_b = oldTimerData.sound) === null || _b === void 0 ? void 0 : _b.label)) {
                if (this.played) {
                    this.SoundService.stop_all();
                    this.$timeout(() => {
                        this.play();
                    }, 100);
                }
                else {
                    // this.played = true;
                }
            }
            Object.assign(this.$modalScope.data, Object.assign(Object.assign({}, timerData), { value: timerData.pomodoro * 60, pomodoro: timerData.pomodoro * 60, shortBreak: timerData.shortBreak * 60, longBreak: timerData.longBreak * 60, longBreakInterval: Math.min(timerData.longBreakInterval, timerData.dailyGoal) }));
        }, true);
    }
    sub(value, min, step) {
        if (typeof this.timerData[value] === 'number') {
            this.timerData[value] -= (this.timerData[value] > min ? step : 0);
        }
        else {
            this.timerData[value] = min;
        }
    }
    add(value, max, step) {
        if (typeof this.timerData[value] === 'number') {
            this.timerData[value] += (this.timerData[value] < max ? step : 0);
        }
        else {
            this.timerData[value] = max;
        }
    }
    play() {
        this.played = true;
        this.SoundService.play(this.timerData.sound.label, this.timerData.soundRepeat, this.timerData.soundVolume / 100).finally(() => {
            this.$scope.$apply(() => {
                this.played = false;
            });
        });
    }
    setPreset(pomodoro, shortBreak, longBreak, longBreakInterval, dailyGoal) {
        this.timerData.pomodoro = pomodoro;
        this.timerData.shortBreak = shortBreak;
        this.timerData.longBreak = longBreak;
        this.timerData.longBreakInterval = longBreakInterval != undefined ? longBreakInterval : this.timerData.longBreakInterval;
        this.timerData.dailyGoal = dailyGoal != undefined ? dailyGoal : this.timerData.dailyGoal;
    }
    debug(pomodoro, shortBreak, longBreak) {
        Object.assign(this.$modalScope.data, Object.assign(Object.assign({}, this.timerData), { value: pomodoro * 60, pomodoro: pomodoro * 60, shortBreak: shortBreak * 60, longBreak: longBreak * 60, longBreakInterval: Math.min(this.timerData.longBreakInterval, this.timerData.dailyGoal) }));
        const instanceKey = this.$modalScope.$random ? this.$modalScope.id + '_' + this.$modalScope.$random : this.$modalScope.id;
        this.modalInstance.close(instanceKey, 'start');
    }
    close(result) {
        const _super = Object.create(null, {
            close: { get: () => super.close }
        });
        return __awaiter(this, void 0, void 0, function* () {
            console.log(this.pomodoroForm);
            if (result == 'start') {
                if (this.pomodoroForm) {
                    if (this.pomodoroForm.$valid) {
                        return _super.close.call(this, 'start');
                    }
                    else {
                        return Promise.resolve();
                    }
                }
            }
            return _super.close.call(this, result);
        });
    }
}
exports.SetPomodoroCtrl = SetPomodoroCtrl;
SetPomodoroCtrl.$inject = [
    '$scope',
    '$locale',
    '$timeout',
    '$location',
    '$element',
    'ConfigService',
    'SoundService',
    'UploadDataUrl'
];
