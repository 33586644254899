"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddTaskCtrl = void 0;
const alert_1 = require("@src/app/components/alert");
const utils_1 = require("@src/shared/utils");
class AddTaskCtrl extends alert_1.AlertComponentCtrl {
    constructor() {
        // static $inject: Array<string> = [
        //     '$scope',
        //     '$locale',
        // ];
        super(...arguments);
        this.data = {
            title: this.$modalScope.data.title,
            taskGoal: (0, utils_1.rangeInitial)(this.$modalScope.data.taskGoal, 1, 1, 500),
            active: this.$modalScope.data.active,
            complete: this.$modalScope.data.complete,
        };
    }
    sub(value, min, step) {
        if (typeof this.data[value] === 'number') {
            this.data[value] -= (this.data[value] > min ? step : 0);
        }
        else {
            this.data[value] = min;
        }
    }
    add(value, max, step) {
        if (typeof this.data[value] === 'number') {
            this.data[value] += (this.data[value] < max ? step : 0);
        }
        else {
            this.data[value] = max;
        }
    }
    close(result) {
        const _super = Object.create(null, {
            close: { get: () => super.close }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (this.taskForm) {
                if (this.taskForm.$valid) {
                    if (result == 'ok') {
                        Object.assign(this.$modalScope.data, this.data);
                    }
                }
            }
            return _super.close.call(this, result);
        });
    }
}
exports.AddTaskCtrl = AddTaskCtrl;
